export default {
  'stc-faucet': '水龙头',
  'stc-authenticated': '认证',
  'stc-how-work': '这是如何运作的？',
  'stc-social-network-URL': '您的Starcoin地址（0x开头, 长度34字符）',
  'stc-give-me': '领取',
  'stc-text-01': '这个 STC 水龙头在',
  'stc-text-02': '网络上运行。为了防止恶意行为者耗尽所有可用资金或积累足够的 STC 来发起长期运行的垃圾邮件攻击，请求与常见的 3rd 方社交网络帐户相关联。任何拥有 Twitter 帐户的人都可以在允许的限额内申请资金。',
  'stc-text-03': '要通过Twitter申请STC，需要发一个',
  'stc-text-04': '，请用你的Starcoin地址（0x开头）替换模版中的`0x00000000000000000000000000000000`，其他文本保持不变。',
  'stc-text-05': '复制黏贴 ',
  'stc-tweets-URL': 'tweets URL',
  'stc-text-06': '到上面的输入框中，然后点击获取！',
  // 'stc-text-07': '您可以在输入字段下方跟踪当前待处理的请求，以查看在轮到您之前需要等待多少时间。',
  'stc-text-07': 'STC到帐时间跟请求数量和网络拥堵有关，正常应该在几分钟以内完成，请耐心等候。',
  'stc-create-success': '提交成功，请稍等几分钟后查看你的钱包余额。',
  'stc-create-bad': '请求异常',
  'stc-add-01': '网络上运行。每个地址每天只能领取一次。',
  'stc-captcha': '请输入图片验证码',
};
