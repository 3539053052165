export default {
  'stc-faucet': 'Faucet',
  'stc-authenticated': 'Authenticated',
  'stc-how-work': 'How does this work?',
  'stc-social-network-URL': 'Your Starcoin address (start with 0x, length=34)',
  'stc-give-me': 'Give me',
  'stc-text-01': 'This STC faucet is running on the',
  'stc-text-02': ' network. To prevent malicious actors from exhausting all available funds or accumulating enough STC to mount long running spam attacks, requests are tied to common 3rd party social network accounts. Anyone having a Twitter account may request funds within the permitted limits.',
  'stc-text-03': 'To request funds via Twitter, make a ',
  'stc-text-04': ', only replace `0x00000000000000000000000000000000` with your Starcoin address (start with 0x) in the content template, keep the other text the same.',
  'stc-text-05': 'Copy-paste the ',
  'stc-tweets-URL': 'tweets URL',
  'stc-text-06': 'into the above input box and fire away!',
  // 'stc-text-07': 'You can track the current pending requests below the input field to see how much you have to wait until your turn comes.',
  'stc-text-07': 'The transfer time of STC is related to the number of requests and the health of the 3rd platform API. Normally, it should be completed within a few minutes. Please wait patiently.',
  'stc-create-success': 'Submit successfully. Please check your account balance a few minutes later.',
  'stc-create-bad': 'bad request',
  'stc-add-01': "Network. Each address can apply once a day.",
  'stc-captcha': 'Please input image captcha',

};
