import Vue from "vue";
import vuex from "vuex";
Vue.use(vuex);

const store = new vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {

    }
});

export default store;
